import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

class Subview extends React.Component {

  render() {
    const { children, classes, maxWidth } = this.props;
    return (
      <Paper className={classes.subviewContainer} style={maxWidth ? { maxWidth }: {}}>
        {children}
      </Paper>
    )
  }
}

const styles = theme => ({
  subviewContainer: {
    padding: theme.spacing.unit * 2,
    position: 'relative',
  }
});

export default withStyles(styles)(Subview);