export const GENERATE_MAD_LIB_REQUEST = 'GENERATE_MAD_LIB_REQUEST';
export const GENERATE_MAD_LIB_SUCCESS = 'GENERATE_MAD_LIB_SUCCESS';
export const GENERATE_MAD_LIB_FAIL = 'GENERATE_MAD_LIB_FAIL';

export const LIKE_MAD_LIB_REQUEST = 'LIKE_MAD_LIB_REQUEST';
export const LIKE_MAD_LIB_SUCCESS = 'LIKE_MAD_LIB_SUCCESS';
export const LIKE_MAD_LIB_FAIL = 'LIKE_MAD_LIB_FAIL';

export const DISLIKE_MAD_LIB_REQUEST = 'DISLIKE_MAD_LIB_REQUEST';
export const DISLIKE_MAD_LIB_SUCCESS = 'DISLIKE_MAD_LIB_SUCCESS';
export const DISLIKE_MAD_LIB_FAIL = 'DISLIKE_MAD_LIB_FAIL';
