import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';

import ComingSoonScreen from '../ComingSoon/ComingSoonScreen';
import MadLibsScreen from '../MadLibs/MadLibsScreen';
import Main from './Main';

const AppRouter = () => (
  <Router>
    <Main>
      <Switch>
        <Route path="/coming-soon" exact component={ComingSoonScreen} />
        <Route path="/mad-libs" exact component={MadLibsScreen} />
        <Redirect from="/" to="/coming-soon" />
      </Switch>
    </Main>
  </Router>
);

export default AppRouter;
