import axios from 'axios';

import {
  GENERATE_MAD_LIB_REQUEST,
  GENERATE_MAD_LIB_SUCCESS,
  GENERATE_MAD_LIB_FAIL,
  LIKE_MAD_LIB_REQUEST,
  LIKE_MAD_LIB_SUCCESS,
  LIKE_MAD_LIB_FAIL,
  DISLIKE_MAD_LIB_REQUEST,
  DISLIKE_MAD_LIB_SUCCESS,
  DISLIKE_MAD_LIB_FAIL,
} from './types';

const API_BASE = 'https://us-central1-code-f5219.cloudfunctions.net';

export const generate = () => async dispatch => {
  dispatch({ type: GENERATE_MAD_LIB_REQUEST });
  try {
    let response = await axios.get(`${API_BASE}/generate`);
    if (response && response.data) {
      dispatch({ type: GENERATE_MAD_LIB_SUCCESS, payload: response.data });
    } else {
      dispatch({ type: GENERATE_MAD_LIB_FAIL });
    }
  } catch (err) {
    dispatch({ type: GENERATE_MAD_LIB_FAIL });
  }
};

export const like = item => async dispatch => {
  dispatch({ type: LIKE_MAD_LIB_REQUEST });
  const ratedItem = { ...item, liked: true };
  try {
    let response = await axios.post(`${API_BASE}/like`, { id: item.id });
    if (response) {
      dispatch({ type: LIKE_MAD_LIB_SUCCESS, payload: ratedItem });
    } else {
      dispatch({ type: LIKE_MAD_LIB_FAIL });
    }
  } catch (err) {
    dispatch({ type: LIKE_MAD_LIB_FAIL });
  }
};

export const dislike = item => async dispatch => {
  dispatch({ type: DISLIKE_MAD_LIB_REQUEST });
  const ratedItem = { ...item, liked: true };
  try {
    let response = await axios.post(`${API_BASE}/dislike`, { id: item.id });
    if (response) {
      dispatch({ type: DISLIKE_MAD_LIB_SUCCESS, payload: ratedItem });
    } else {
      dispatch({ type: DISLIKE_MAD_LIB_FAIL });
    }
  } catch (err) {
    dispatch({ type: DISLIKE_MAD_LIB_FAIL });
  }
};
