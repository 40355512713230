import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Textfit } from 'react-textfit';

import imgPath from '../../assets/images/tree-background.jpg';

class Main extends React.Component {

  render() {
    const { children, classes } = this.props;
    return (
      <div className={classes.mainContainer}>
        <Typography variant="h1" className={classes.watermark}>
          <Textfit mode="single">codeservative</Textfit>
        </Typography>
        {children}
      </div>
    )
  }
}

const styles = theme => ({
  mainContainer: {
    alignItems: 'center',
    backgroundImage: `url(${imgPath})`,
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  watermark: {
    bottom: 0,
    color: 'rgba(255,255,255,0.5)',
    position: 'absolute',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    textAlign: 'right',
    width: '100%',
  },
});

export default withStyles(styles)(Main);