import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Component } from 'react';

import AppRouter from './modules/Layout/AppRouter';

export default class App extends Component {

  render() {
    return (
      <div style={{ height: '100%' }}>
        <CssBaseline />
        <AppRouter />
      </div>
    );
  }
}
