import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import Subview from '../Layout/Subview';

class ComingSoonScreen extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Subview>
        <Typography className={classes.textCenter} variant="h2">Coming Soon</Typography>
      </Subview>
    );
  }
}

const styles = theme => ({
  textCenter: {
    textAlign: 'center',
  },
});

ComingSoonScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
)(ComingSoonScreen);
