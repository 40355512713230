import produce from 'immer';

import {
  GENERATE_MAD_LIB_REQUEST,
  GENERATE_MAD_LIB_SUCCESS,
  GENERATE_MAD_LIB_FAIL,
  LIKE_MAD_LIB_REQUEST,
  LIKE_MAD_LIB_SUCCESS,
  LIKE_MAD_LIB_FAIL,
  DISLIKE_MAD_LIB_REQUEST,
  DISLIKE_MAD_LIB_SUCCESS,
  DISLIKE_MAD_LIB_FAIL,
} from './types';

const INITIAL_STATE = {
  isLoading: false,
  items: [],
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case GENERATE_MAD_LIB_REQUEST:
      draft.isLoading = true;
      break;
    case GENERATE_MAD_LIB_SUCCESS:
      draft.isLoading = false;
      draft.items.push(action.payload);
      break;
    case LIKE_MAD_LIB_SUCCESS:
    case DISLIKE_MAD_LIB_SUCCESS: {
      const madLib = action.payload;
      const index = draft.items.findIndex(item => item.id === madLib.id);
      if (index >= 0) {
        draft.items[index] = madLib;
      }
      draft.isLoading = false;
      break;
    }
    case GENERATE_MAD_LIB_FAIL:
      draft.isLoading = false;
      break;
    default:
      break;
  }
  return draft;
}, INITIAL_STATE);
