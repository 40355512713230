import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';
import TweetEmbed from 'react-tweet-embed'
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import Subview from '../Layout/Subview';
import { generate, like, dislike } from './actions';
import { IconButton, Icon } from '@material-ui/core';

const GENERATE_VIEW = 'GENERATE_VIEW';
const ABOUT_VIEW = 'ABOUT_VIEW';

class MadLibsScreen extends React.Component {

  state = {
    index: -1,
    view: GENERATE_VIEW,
  };

  componentDidMount() {
    const length = this.props.madLibs.items.length;
    if (length === 0) {
      this.generate();
    } else {
      this.setState({ index: length - 1 });
    }
  }

  generate = async () => {
    await this.props.generate();
    this.next();
  };

  prev = () => this.setState({ index: this.state.index - 1 });
  next = () => this.setState({ index: this.state.index + 1 });
  canPrev = () => this.state.index > 0;
  canNext = () => this.state.index < this.props.madLibs.items.length - 1;

  renderGeneratedText() {
    const { classes, madLibs } = this.props;
    const { items } = madLibs;
    const { index } = this.state;
    const item = items[index];

    if (!item) {
      return null;
    }

    return (
      <div className={classes.textContainer}>
        <Typography className={classes.generatedText} gutterBottom inline>
          {item.text}
        </Typography>
        <IconButton color="primary" onClick={() => this.props.like(item)}>
          <Icon>thumb_up</Icon>
        </IconButton>
        <IconButton color="secondary" onClick={() => this.props.dislike(item)}>
          <Icon>thumb_down</Icon>
        </IconButton>
      </div>
    );
  }

  renderGenerateView() {
    const { classes, madLibs } = this.props;
    const { isLoading, items } = madLibs;
    const { index, view } = this.state;
    if (view !== GENERATE_VIEW) {
      return null;
    }

    return (
      <>
        <Button
          color="primary"
          disabled={isLoading}
          onClick={this.generate}
          variant="contained"
        >
          Generate
        </Button>
        {this.renderGeneratedText(items[index])}
        <div className={classes.center}>
          <IconButton disabled={!this.canPrev()} onClick={this.prev}>
            <Icon>chevron_left</Icon>
          </IconButton>
          <IconButton disabled={!this.canNext()} onClick={this.next}>
            <Icon>chevron_right</Icon>
          </IconButton>
        </div>
      </>
    );
  }

  renderAboutView() {
    const { view } = this.state;
    if (view !== ABOUT_VIEW) {
      return null;
    }

    return (
      <>
        <Typography variant="body1">Inspired by Charles C. W. Cooke:</Typography>
        <TweetEmbed id="1098636906005438464" />
        <Typography  variant="body1">Send feedback and new content to&nbsp;
          <Link color="primary" href="https://twitter.com/codeservative" target="blank" rel="noopener">@codeservative</Link>
        </Typography>
      </>
    );
  }

  renderViewToggle() {
    switch (this.state.view) {
      case GENERATE_VIEW:
        return <Button color="primary" variant="text" onClick={() => this.setState({ view: ABOUT_VIEW })}>About</Button>;
      case ABOUT_VIEW:
      return <Button color="primary" variant="text" onClick={() => this.setState({ view: GENERATE_VIEW })}>Generate</Button>;
      default:
        return null;
    }
  }

  render() {
    const { classes, madLibs } = this.props;
    const { isLoading } = madLibs;
    return (
      <Subview maxWidth="80%">
        {isLoading && <CircularProgress className={classes.loading} size={24} color="primary" />}
        <div className={classes.headerRow}>
          <Typography variant="h2" gutterBottom inline>MadLibs</Typography>
          {this.renderViewToggle()}
        </div>
        {this.renderGenerateView()}
        {this.renderAboutView()}
      </Subview>
    );
  }
}

const styles = theme => ({
  center: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  generatedText: {
    marginRight: theme.spacing.unit,
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loading: {
    position: 'absolute',
    right: theme.spacing.unit * 2,
    bottom: theme.spacing.unit * 2,
  },
  textContainer: {
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
  },
});

const mapStateToProps = state => {
  const { madLibs } = state;
  return { madLibs };
};

MadLibsScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  madLibs: PropTypes.object.isRequired,
  generate: PropTypes.func.isRequired,
  like: PropTypes.func.isRequired,
  dislike: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { generate, like, dislike }),
)(MadLibsScreen);
